import React from "react";
import Layout from "../components/layout";
import {Row, Col} from "react-bootstrap"
import SEO from "../components/seo";


const CoopEnLinea = () => (
    <Layout>
        <SEO title="Cooperativa en línea" description="Portal de la cooperativa en línea"  />
        <Row>
        </Row>
    </Layout>
);

export default CoopEnLinea;